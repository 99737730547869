import React, { useEffect } from "react";
import styled from "styled-components";
import Util from "../util";
import Input from './Input';
const CredentialsForm = ({ aws, change }) => {
  const [formValuesAWS, setFormValuesAWS] = React.useState({
    accessKeyId: null,
    secretAccessKey: null,
  });

  useEffect(() => {
    const cookie = new Util();
    const aws_cookie = cookie.getCookie("_awscookie");
    if (aws_cookie) {
      const v = JSON.parse(window.atob(aws_cookie));
      setFormValuesAWS({ accessKeyId: v.key, secretAccessKey: v.secret });
    }
  }, []);
  const handleChange = (e) => {
    change(true);
    setFormValuesAWS((old) => ({ ...old, accessKeyId: e.target.value }));
  };
  const handleChange2 = (e) => {
    change(true);
    setFormValuesAWS((old) => ({ ...old, secretAccessKey: e.target.value }));
  };
 
  return (
    <FormStyle>
      <label>AWS credentials</label>
      <Input placeholder="Access Key" value={formValuesAWS.accessKeyId} handleOnChange={value => handleChange(value)} />
      <Input placeholder="Secret Access Key" value={formValuesAWS.secretAccessKey} handleOnChange={value => handleChange2(value)} />
      <button
        disabled={!formValuesAWS.accessKeyId || !formValuesAWS.secretAccessKey}
        onClick={() => aws({
          accessKeyId: formValuesAWS.accessKeyId,
          secretAccessKey: formValuesAWS.secretAccessKey,
        })}
      >
        Submit
      </button>
    </FormStyle>
  );
};
const FormStyle = styled.div`
  position: fixed;
  width: 200px;
  right: 0;
  padding: 10px;
  background: #ffeb3b;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .cred {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    justify-content: center;
    gap:10px;
    svg{
      font-size:1rem;
      cursor:pointer;
    }
    input{
      padding:10px 0;
      &:focus{
        outline:none
      }
    }
  }
  input {
    border: none;
    border-bottom: 1px solid grey;
    padding: 10px;
    background: transparent;
  }
  button {
    padding: 10px;
    font-family: "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
    cursor: pointer;
    background: #077db3;
    border: none;
    color: white;
    &:hover {
      background: #053c55;
    }
    &:disabled {
      background: grey;
    }
  }
`;
export default CredentialsForm;
