import React, { useState, createRef, useEffect } from "react";
import Left from "../chevron-left.svg";

const History = ({
  list,
  toglebehavior,
  toggle,
  setgrouplink,
  removeSpecific,
  grouplinks,
}) => {
  const ref = createRef();
  const [copied, setCopied] = useState("");
  const [zoom, setzoom] = useState(null);
  const [localFiles, setLocalFiles] = useState([]);
  const [showLocal, setShowLocal] = useState(false);

  const copyUrl = (url) => {
    ref.current.value = `http://shot.screen1.me/${url}`;
    ref.current.select();
    document.execCommand("copy");
    ref.current.setSelectionRange(0, 99999);
    ref.current.focus();

    setCopied(url);
    setTimeout(() => {
      setCopied("");
    }, 2000);
  };

  const copyUrlGroup = (urls) => {
    ref.current.value = urls.replaceAll(".png", ".png\t\t");
    ref.current.select();
    document.execCommand("copy");
    ref.current.setSelectionRange(0, 99999);
    ref.current.focus();
  };
  const mouseenter = (event) => {
    event.stopPropagation();
    setzoom(<ZoomViewer props={event}></ZoomViewer>);
  };
  const downloadList = () => {
    const element = document.createElement("a");
    const file = new Blob(
      [
        list
          .map((l) => "http://shot.screen1.me/" + l)
          .join(",")
          .replaceAll(",", "\n"),
      ],
      { type: "text/plain" }
    );
    element.href = URL.createObjectURL(file);
    element.download = "s3BW-links-" + Date.now() + ".txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  const loadLocalStorage = () => {
    const data = localStorage.getItem("files");

    if (data) {
      console.log(`JSON.parse(data)`, JSON.parse(data));
      setLocalFiles(JSON.parse(data));
      setShowLocal(true)
    }
  };
  const ItemHistory = (key) => {
    return (
      <>
        <img
          src={
            key.indexOf(".mp4") > 0
              ? "https://cdn-icons-png.flaticon.com/512/92/92353.png"
              : "https://s3.amazonaws.com/shot.screen1.me/" + key
          }
          onMouseOut={() => setzoom(true)}
          onMouseOver={(e) => mouseenter(e)}
          width="64"
          height="64"
          onClick={() => setgrouplink("http://shot.screen1.me/" + key + "\n")}
        ></img>
        <span onClick={() => copyUrl(key)}>
          {"http://shot.screen1.me/" + key}
        </span>
        {copied == key && <div className="copied">Copied</div>}
        <div
          className="remove"
          onClick={() => {
            if (
              window.confirm("Are you sure you wish to delete this screenshot?")
            ) {
              removeSpecific(key);
              setgrouplink(key, "remove");
            }
          }}
        >
          x
        </div>
      </>
    );
  };
  const clearLocalStorage = () => {
    localStorage.removeItem("files");
    setLocalFiles([]);
  };
  
  return (
    <div className={toggle ? "story-class active" : "story-class"}>
      <div className="head">
        <label>Screenshots</label>
        <img
          className={!toggle ? "close" : ""}
          src={Left}
          width="15"
          onClick={() => toglebehavior(!toggle)}
        ></img>
      </div>

      <ul>
        {list?.map((key, index) => (
          <li key={index}>{ItemHistory(key)}</li>
        ))}
      </ul>
      {list && list.length > 0 && (
        <div className="btn-container">
          {" "}
          <button
            className="addAll"
            width="100"
            onClick={() => {
              setgrouplink(
                list.map((key) => "http://shot.screen1.me/" + key).join("\n")
              );
              copyUrlGroup(
                list.map((key) => "http://shot.screen1.me/" + key).join("\n")
              );
            }}
          >
            Copy all
          </button>
          <button className="addAll" onClick={downloadList}>
            Download List
          </button>
        </div>
      )}
      <div className="localSection">
        <button className="addAll" onClick={() => loadLocalStorage()}>
          Load Storage
        </button>
        {localFiles.length > 0 && (
          <button className="addAll" onClick={() => clearLocalStorage()}>
            Remove Storage
          </button>
        )}
       <img
          className={!showLocal ? "btn close" : "btn"}
          
          src={Left}
          width="15"
          onClick={() => setShowLocal(!showLocal)}
        ></img>
        <ul>
          {localFiles?.map((key, index) => {
            return <li key={index}>{ItemHistory(key)}</li>;
          })}
        </ul>
      </div>
      <textarea ref={ref} type="text" style={{ opacity: 0 }}></textarea>
      {zoom && zoom}
    </div>
  );
};
const ZoomViewer = ({ props }) => {
  let {
    clientY,
    clientX,
    target: { src },
  } = props;

  const viewportHeight = window.innerHeight;
  if (viewportHeight - viewportHeight / 3 < clientY)
    clientY = clientY - viewportHeight / 2;
  return (
    <img
      className="zoomed"
      src={src}
      style={{ left: clientX, top: clientY }}
    ></img>
  );
};

export default History;
