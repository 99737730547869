import sha256 from 'crypto-js/sha256';

class Util {
    value = null;
    constructor(value){
        this.value = value;
    }
    matchCode(password,validate=false) {
        if (validate) {
            const a = atob("U2FpbDdTZWFz");
            password = a;
        }
        const matchExp = "dd5a675226f82a40e892a983df7a5d5c8dae3528a7d2e7a19f93f79bccec541b";
        const hashDigest = sha256(password);
        return matchExp == hashDigest ? matchExp : null;
    }
    setCookie(name, value, days = 1) {
        if(!value)
        value = this.value

        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
}


export default Util;