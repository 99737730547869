import React from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
const Input = ({placeholder, value, handleOnChange }) => {
  const [showCredentials, setShowCredentials] = React.useState({
    key: false,
    secret: false,
  });

  return (
    <div className="cred">
      {showCredentials.key ? (
        <VisibilityIcon
          onClick={() =>
            setShowCredentials((old) => ({ ...old, key: !old.key }))
          }
        ></VisibilityIcon>
      ) : (
        <VisibilityOffIcon
          onClick={() =>
            setShowCredentials((old) => ({ ...old, key: !old.key }))
          }
        ></VisibilityOffIcon>
      )}

      <input
        placeholder={placeholder}
        value={value ?? ""}
        onChange={(e) => handleOnChange(e)}
        type={showCredentials.key ? "text" : "password"}
      ></input>
    </div>
  );
};
export default Input;
