import React, { useRef,useState,useEffect } from 'react'
import styled from 'styled-components';
import Util from '../util'
const util = new Util();

const Login = (props) => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false)
    const ref = useRef()
    useEffect(() => {
        ref.current.focus()
    }, [])
    const loggedIn = e => {
        e.preventDefault();

        const exist = util.matchCode(password)
        if (exist) {
            util.setCookie('_bwtoken', exist);
            props.valid(true)
        }
        else {
            setError(true)
        }
    }
    
    return <StyledLogin>
        <img src="/logo-bww-detailed.png"></img>
        <form onSubmit={e => loggedIn(e)}>
            <input type="password" ref={ref} onChange={e => { setPassword(e.target.value); setError(false); }} value={password} required></input>
            <button>Log In</button>
            <div className={error ? 'error active' : 'error'} >Credentials error</div>
        </form>
        
    </StyledLogin>
}
const StyledLogin = styled.div`
background:rgb(39, 39, 39);
height:100vh;
width:100vw;
display:flex;
align-items:center;
justify-content:center;
flex-direction:column;
form{
    width:241px;
    height:150px;
    flex-direction:column;
    display:flex;
    align-items:center;
    justify-content:center;
    input{
        border:none;
        border-bottom:2px solid grey;
        display:block;
        width:100%;
        margin:10px;
        background:transparent;
        color:#077db3;
        font-size:30pt;
        text-align:center;
        &:focus{
            border:none;
            outline:none;
            border-bottom:2px solid #077db3;
        }
    }
    button{
        background:#ffcb00;
        border:none;
        line-height:3;
        width:100px;
        font-weight:1000;
        cursor:pointer;
        &:hover{
            background:#78651b;
            color:white
        }
    }
        
        .error{
            background:red;
            color:white;
            margin:10px;
            padding:10px;
            border-radius:10px;
            opacity:0;
            &.active{
                opacity:1
            }
        }
}


`

export default Login;