import React,{useState} from 'react'

const GroupLink = (props) => {
    const {copylist,setgrouplink} = props
    const [position,setposition] = useState({bottom:0,right:0})
    const handleDragEnd = (e) => {
        
        console.log(e.clientY)
        console.log(e.clientX)
        
        setposition({
            bottom:  (window.innerHeight - e.clientY) + "px" ,// (e.target.offsetHeight) ,
            right: (window.innerWidth - e.clientX) + "px" ,// (e.target.offsetWidth)
        })
    }

    return <div className="grouplink" style={position} >
        <div className="move" onDragEnd={handleDragEnd} draggable>+</div>
        <textarea value={copylist} onChange={e => setgrouplink(e.target.value)}>
        
        </textarea>
            
    </div>
}

export default GroupLink;